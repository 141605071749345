@tailwind base;
@tailwind components;
@tailwind utilities;

.rhap_button-clear.rhap_repeat-button {
  display: none;
}

.rhap_container {
  border: 1px solid #c0c0c0 !important;
  border-radius: 4px !important;
  box-shadow: none !important;
}
